
import { setPageHeader } from '@/core/helpers/toolbar';
import { defineComponent, onMounted } from 'vue';
import ProductGroupList from '@/modules/catalog/product-groups/components/ProductGroup.vue';
import AddProductGroup from '@/modules/catalog/product-groups/components/AddProductGroup.vue';
import EditProductGroup from '@/modules/catalog/product-groups/components/EditProductGroup.vue';
import AssignProductGroup from '@/modules/catalog/product-groups/components/AssignProducts.vue';
import ViewProductGroup from '@/modules/catalog/product-groups/components/ViewProducts.vue';

export default defineComponent({
    name: 'product_groups',
    components: {
        ProductGroupList,
        AddProductGroup,
        EditProductGroup,
        AssignProductGroup,
        ViewProductGroup
    },
    setup() {
        onMounted(() => {
            setPageHeader({
                title: 'message.PRODUCT_GROUPS',
                actionButton: {
                    ability: 'add_product_group',
                    pageAction: {
                        action: 'AddProductGroupAction'
                    },
                    button: {
                        title: `message.ADD message.PRODUCT_GROUPS`,
                        icon: 'bi bi-plus',
                        openModal: true
                    }
                },
                breadCrumbs: [{ name: 'message.HOME', link: '/dashboard' }, { name: 'message.CATALOG', link: '#' }, { name: 'message.PRODUCT_GROUPS' }]
            });
        });
    }
});
