import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductGroupList = _resolveComponent("ProductGroupList")!
  const _component_AddProductGroup = _resolveComponent("AddProductGroup")!
  const _component_EditProductGroup = _resolveComponent("EditProductGroup")!
  const _component_AssignProductGroup = _resolveComponent("AssignProductGroup")!
  const _component_ViewProductGroup = _resolveComponent("ViewProductGroup")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_ProductGroupList)
    ]),
    _createVNode(_component_AddProductGroup),
    _createVNode(_component_EditProductGroup),
    _createVNode(_component_AssignProductGroup),
    _createVNode(_component_ViewProductGroup)
  ], 64))
}