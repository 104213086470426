
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject } from 'vue';
import { Input } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { GET_CUSTOMER_GROUP } from '@/modules/customer/customer-groups/graphql/Queries';
import { UPDATE_PRODUCT_GROUP } from '@/modules/catalog/product-groups/graphql/Mutations';
import { EditProductGroupForm } from '@/modules/catalog/product-groups/interfaces';
import { useI18n } from 'vue-i18n';
import InnerLoader from '@/components/InnerLoader.vue';

export default defineComponent({
    name: 'edit customer group',
    components: {
        Input,
        InnerLoader
    },

    setup() {
        const modal: any = ref();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const current_page = ref(1);
        const i18n = useI18n();
        const editProductGroupForm = ref<null | HTMLFormElement>(null);

        // Given Edit Customer Group Form Interface
        const editProductGroupData = ref<EditProductGroupForm>({
            id: null,
            name: '',
            uuid: ''
        });

        // Submit Hanlder Request
        const submitHandlerForm = async () => {
            editProductGroupForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        uuid: editProductGroupData.value.uuid,
                        name: editProductGroupData.value.name
                    };

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: UPDATE_PRODUCT_GROUP,
                        variables: { input: formData },
                        update: (store, { data: { update_product_group } }) => {
                            modal.value.hide();
                            loading.value = false;
                            Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                        }
                    }).catch(() => {
                        loading.value = false;
                    });
                }
            });
        };

        // Reset Form Data On Model Popup
        const resetForm = () => {
            emitter.emit('clearInput');
            current_page.value = 1;
            editProductGroupForm.value?.resetFields();
        };

        // Emitter To Open Model
        emitter.on('editProductGroupAction', (product_group: Record<any, any>) => {
            resetForm();
            current_page.value = product_group.current_page;
            editProductGroupData.value.id = product_group.id;
            editProductGroupData.value.name = product_group.name;
            editProductGroupData.value.uuid = product_group.uuid;
            modal.value = new Modal(document.getElementById('modal_edit_product_group'));
            modal.value.show();
        });

        return {
            loading,
            editProductGroupData,
            editProductGroupForm,
            submitHandlerForm
        };
    }
});
