
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, computed } from 'vue';
import { Select } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { GET_CUSTOMER_GROUP } from '@/modules/customer/customer-groups/graphql/Queries';
import { GET_PRODUCT_GROUP_PRODUCTS } from '@/modules/catalog/product-groups/graphql/Queries';
import { UPDATE_GROUP_PRODUCTS } from '@/modules/catalog/product-groups/graphql/Mutations';
import { EditProductGroupForm } from '@/modules/catalog/product-groups/interfaces';
import { useI18n } from 'vue-i18n';
import InnerLoader from '@/components/InnerLoader.vue';
import { CUP_AGREEMENT_PRODUCT } from '@/modules/catalog/products/graphql/Queries';

export default defineComponent({
    name: 'edit customer group',
    components: {
        // Input,
        Select,
        InnerLoader
    },

    setup() {
        const loader = ref(false);
        const products = ref([]) as Record<any, any>;
        const modal: any = ref();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const current_page = ref(1);
        const i18n = useI18n();
        const editProductGroupForm = ref<null | HTMLFormElement>(null);
        const group_products = ref([]) as Record<any, any>;
        const product_group_id = ref();
        const assignedProductsData = ref([]) as Record<any, any>;
        const assignedProducts = ref([]) as Record<any, any>;
        const assignedProduct = ref([]) as Record<any, any>;

        // Given Edit Customer Group Form Interface
        const editProductGroupData = ref<EditProductGroupForm>({
            id: null,
            name: '',
            uuid: ''
        });

        const selectedProducts = computed(() => {
            return assignedProductsData.value.map(value => {
                return products.value.find(product => product.id === value.product_id);
            });
        });

        // Submit Hanlder Request
        const submitHandlerForm = async () => {
            const formData = {
                product_id: assignedProducts.value,
                id: product_group_id.value
            };
            loading.value = true;
            await Apollo.mutate({
                mutation: UPDATE_GROUP_PRODUCTS,
                variables: { input: formData },
                update: (store, { data: { update_group_products } }) => {
                    modal.value.hide();
                    loading.value = false;
                    Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                }
            }).catch(() => {
                loading.value = false;
            });
        };

        // Reset Form Data On Model Popup
        const resetForm = () => {
            emitter.emit('clearInput');
            current_page.value = 1;
            editProductGroupForm.value?.resetFields();
        };

        const getProducts = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: CUP_AGREEMENT_PRODUCT,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                products.value = [];
                data.products.data.forEach(element => {
                    products.value.push({
                        value: element.id,
                        label: JSON.parse(element.description.name).en.name
                    });
                });

                loader.value = false;
            });
        };

        getProducts();

        const getProductGroupProducts = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_PRODUCT_GROUP_PRODUCTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: { id: product_group_id.value }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                assignedProductsData.value = JSON.parse(data?.product_group_products);

                assignedProducts.value = [];
                assignedProductsData.value.forEach(element => {
                    assignedProducts.value.push(element.product_id);
                });

                loader.value = false;
            });
        };

        // Emitter To Open Model
        emitter.on('assignProductGroupAction', (product_group: Record<any, any>) => {
            resetForm();
            product_group_id.value = product_group.id;
            getProductGroupProducts();
            modal.value = new Modal(document.getElementById('modal_assign_product_group'));
            modal.value.show();
        });

        return {
            loading,
            editProductGroupData,
            editProductGroupForm,
            submitHandlerForm,
            products,
            group_products,
            assignedProductsData,
            assignedProducts,
            selectedProducts,
            assignedProduct
        };
    }
});
