
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, watchEffect } from 'vue';
import { Apollo } from '@/core/services';
import { GET_GROUP_PRODUCTS } from '@/modules/catalog/product-groups/graphql/Queries';
import { useI18n } from 'vue-i18n';
import InnerLoader from '@/components/InnerLoader.vue';
import Table from '@/components/Table/Table.vue';


export default defineComponent({
    name: 'view customer group',
    components: {
        Table, InnerLoader
    },

    setup() {
        const loader = ref(false);
        const products = ref([]) as Record<any, any>;
        const modal: any = ref();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const current_page = ref(1);
        const i18n = useI18n();
        const group_products = ref([]) as Record<any, any>;
        const product_group_id = ref();
        const systemLocale = ref();

        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name',
                sorting: true
            },
            {
                label: 'message.PRODUCT_NUMBER',
                key: 'economic_number',
                sorting: true
            },
            {
                label: 'message.QUANTITY',
                key: 'available_stock',
                sorting: true
            },
            {
                label: 'message.STATUS',
                key: 'status'
            }
        ]);

        watchEffect(() => {
            systemLocale.value = i18n.fallbackLocale.value;
        });

        const getGroupProducts = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_GROUP_PRODUCTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    product_group_id: product_group_id.value
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                products.value = data.group_products.products

                loader.value = false;
            });
        };

        // Emitter To Open Model
        emitter.on('viewGroupProductsAction', (product_group: Record<any, any>) => {
            product_group_id.value = product_group.id;
            console.log('modal', document.getElementById('modal_view_group_products'))
            modal.value = new Modal(document.getElementById('modal_view_group_products'));
            modal.value.show();

            getGroupProducts();
        });

        return {
            systemLocale,
            columns,
            loading,
            products,
            group_products,
            loader
        };
    }
});
